<template>
  <div>
    <v-row justify="center" v-if="this.dialog">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Follow Up</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-col class="d-flex" cols="12" sm="12">
                  <v-radio-group @change="assignConsultation" row v-model="followUpWith"
                                 v-if="consultation.followUpConsultation.location.consultation_enabled">
                    <v-radio label="Follow up with other doctor" value="other"></v-radio>
                    <v-radio label="Follow up with the same doctor" value="same"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    v-if="consultation.followUpConsultation.location.next_shifts.length && followUpWith === 'same'"
                    :items="consultation.followUpConsultation.location.next_shifts"
                    item-text="date"
                    item-value="date"
                    label="Select shift.."
                    v-model="formData.scheduled_to"
                    outlined>
                  </v-select>
                </v-col>
                <v-col class="d-flex hidden" cols="12" sm="6">
                  <v-select
                    :items="durations"
                    v-model="formData.duration"
                    item-text="duration_text"
                    item-value="id"
                    label="Select duration.."
                    outlined>
                  </v-select>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Question body"
                    v-model="formData.question"
                    disabled
                  ></v-textarea>
                </v-col>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="dialog = false">Close</v-btn>
            <v-btn color="primary" @click="submit" :loading="submitting" :disabled="submitting">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <SuccessDialog ref="successDialog" message="Follow up consultation created successfully"></SuccessDialog>
    <ErrorDialog ref="errorDialog" :message="errorMessage"></ErrorDialog>
  </div>
</template>

<script>
  import SuccessDialog from "../../components/base/SuccessDialog";
  import ErrorDialog from "../../components/base/ErrorDialog";

  export default {
    name: "FollowUpForm",
    components: {ErrorDialog, SuccessDialog},
    created() {
      this.getDurationList();
    },
    data() {
      return {
        submitting: false,
        dialog: false,
        consultation: {},
        followUpWith: 'other',
        formData: {
          scheduled_to: null,
          question: '',
          parent_consultation_id: '',
          memberInfo: {
            phone: null
          }
        },
        durations: [],
        errorMessage: ''
      }
    },
    methods: {
      showFollowUpForm(consultation) {
        this.dialog = true;
        this.consultation = consultation;
        this.formData.question = consultation.serviceQuestion.question_body;
        this.formData.parent_consultation_id = consultation.followUpConsultation.consultation_id;
        this.formData.memberInfo.phone = consultation.phr && consultation.phr.phone ? consultation.phr.phone : consultation.member.phone;
        this.formData.duration = this.durations[0].id;
      },
      assignConsultation() {
        if (this.followUpWith === 'same') {
          this.$delete(this.formData, 'parent_consultation_id');
          this.$set(this.formData, 'follow_up_consultation_id', this.consultation.followUpConsultation.consultation_id);
        } else if (this.followUpWith === 'other') {
          this.$set(this.formData, 'parent_consultation_id', this.consultation.consultation_id);
          this.$delete(this.formData, 'follow_up_consultation_id');
          this.$delete(this.formData, 'scheduled_to');
        }
      },
      getDurationList() {
        this.$authApi.get('durations/active').then(res => {
          this.durations = res.data.data
        }).catch(err => {
          console.log(err)
        })
      },
      submit() {
        this.submitting = true;
        this.$authApi.post('consultations', this.formData).then(res => {
          this.submitting = false;
          this.dialog = false;
          this.$refs.successDialog.openDialog();
        }).catch(error => {
          this.submitting = false;
          this.dialog = false;
          this.$refs.errorDialog.openDialog();
          this.errorMessage = JSON.stringify(error.response.data.message.message).replace('{', '').replace('}', '');
        })
      }
    }
  }
</script>

<style scoped>
  .hidden {
    display: none !important;
  }
</style>
