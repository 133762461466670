<template>
  <v-app class="transparent">
    <v-container fluid>
      <div class="text-center vertically-centered" v-if="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-else>
        <div class="my-2 text-right" v-if="consultation.followUpConsultation">
          <v-btn x-large color="info" dark @click="showFollowUpForm">Follow up consultation</v-btn>
        </div>
        <div>
          <v-expansion-panels
            v-if="!loading"
            v-model="panel"
            :disabled="disabled"
            multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="display-2">Question Body</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="display-1">
                  {{ consultation.serviceQuestion.question_body }}
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="consultation.recordingUrl">
              <v-expansion-panel-header class="display-2">Recording</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="display-1">
                  <audio crossorigin="" playsinline=""  width="100%" height="40px" controls="" preload="none" id="player">
                    <source :src="consultation.recordingUrl" type="audio/mp3">
                    Your browser does not support the audio element.
                  </audio>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header class="display-2">Recommendation Info</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                    <tr>
                      <td>icd10 symptom</td>
                      <td>
                        <template v-for="symptom in consultation.callbackRecommendationInfo.icd10.symptom">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ symptom.name }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td>icd10 diagnosis</td>
                      <td>
                        <template v-for="diagnosis in consultation.callbackRecommendationInfo.icd10.diagnosis">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ diagnosis.name }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td>Drugs</td>
                      <td>
                        <template v-for="drug in consultation.callbackRecommendationInfo.drug.drug">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ drug.name }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td>Drug Commercial</td>
                      <td>
                        <template v-for="drugCommercial in consultation.callbackRecommendationInfo.drug.drugCommercial">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ drugCommercial.name }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td>Fda Drugs</td>
                      <td>
                        <template v-for="fdaDrug in consultation.callbackRecommendationInfo.drug.fdaDrug">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ fdaDrug.name }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td>Labs</td>
                      <td>
                        <template v-for="lab in consultation.callbackRecommendationInfo.lab.lab">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ lab.name }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td>Lab Panel</td>
                      <td>
                        <template v-for="panel in consultation.callbackRecommendationInfo.lab.panel">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ panel.name }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td>Doctor Referral</td>
                      <td>
                        <v-chip
                          class="ma-2 title"
                          color="default">
                          {{ consultation.callbackRecommendationInfo.doctorReferral.name }}
                        </v-chip>
                      </td>
                    </tr>
                    <tr>
                      <td>Doctor follow up</td>
                      <td>
                        <template v-for="followup in consultation.callbackRecommendationInfo.followUp">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ followup.name }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr>
                      <td>Doctor Notes</td>
                      <td>
                        <template v-for="doctorNotes in consultation.postCallAnswers">
                          <v-chip
                            class="ma-2 title"
                            color="default">
                            {{ doctorNotes.question }} : {{ doctorNotes.answer_body }}
                          </v-chip>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="consultation.prescriptionPdfUrl && consultation.callbackRecommendationInfo.drug.fdaDrug.length > 0">
                      <td>
                        <div class="ma-2">
                          <v-btn x-large color="success" dark @click="downloadPrescription(consultation.prescriptionPdfUrl)">
                            Download Prescription
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <FollowUpForm ref="followUpForm"></FollowUpForm>
      </template>
    </v-container>
  </v-app>
</template>

<script>
  import FollowUpForm from "./FollowUpForm";
  export default {
    name: "ShowConsultation",
    components: {FollowUpForm},
    created() {
      this.getConsultation();
    },
    data() {
      return {
        loading: false,
        showFollowUp: false,
        consultation_id: this.$route.params.id,
        panel: [0, 1],
        disabled: false,
        readonly: false,
        consultation: {},
      }
    },
    methods: {
      downloadPrescription(url) {
        window.location.href = url
      },
      getConsultation () {
        this.loading = true;
        this.$authApi.get(`consultations/${this.consultation_id}`).then(res => {
          this.consultation = res.data.data;
          this.loading = false;
        })
      },
      showFollowUpForm() {
        this.$refs.followUpForm.showFollowUpForm(this.consultation);
      }
    }
  }
</script>

<style scoped>
  .vertically-centered {
    margin: 20% auto
  }
</style>
